import React from 'react';

import {
  Status,
  StyledTemperatureGauge,
  StyledTemperatureGaugeWrapper,
  TempNumber,
  TempType,
  InfoWrapper,
  GaugeTemp,
  OfflineMessage,
  InfoWrapperContent,
  InfoWrapperContentText,
  InfoWrapperTemperature,
  InfoWrapperCloseButton,
  DualInfoWrapper,
  InfoWrapperStatus,
  InfoWrapperFan,
} from 'pages/thermostat/temperature-gauge/styles';
import { ThermostatData } from 'types/thermostatData';

import { TemperatureSliderProps } from '../../../types/thermostatThemperatureSliderProps';
import { Remove } from '../../../components/icons/x-remove';
import { ThermostatPageSettings } from '../../../types/thermostatPageSettings';

export interface TemperatureGaugeProps {
  desiredTemperature: number;
  currentTemperature: number;
  desiredCoolTemperature: number;
  desiredHeatTemperature: number;
  selectedThermostat: ThermostatData;
  hvacMode: TemperatureSliderProps['type'];
  hold: ThermostatPageSettings['hold'];
  onResumeProgram: () => void;
}

export const TemperatureGauge = ({
  currentTemperature,
  desiredTemperature,
  desiredHeatTemperature,
  desiredCoolTemperature,
  selectedThermostat,
  hvacMode,
  hold,
  onResumeProgram,
}: TemperatureGaugeProps) => {
  const offline = selectedThermostat?.runtime?.connected === false;
  /* eslint no-confusing-arrow: ["off", {"allowParens": false}] */
  const renderDualInfoWrapper = () => hold && hold.endTime ? (
    <InfoWrapper>
      <InfoWrapperContent>
        <InfoWrapperContentText key={hvacMode} mode={hvacMode}>
          <InfoWrapperTemperature key={hvacMode} mode="heat">
            {desiredHeatTemperature}
          </InfoWrapperTemperature>
          -&nbsp;
          {desiredCoolTemperature}
            &nbsp; until&nbsp;
          {hold.endTime}
            &nbsp;
          <InfoWrapperCloseButton onClick={onResumeProgram}>
            <Remove />
          </InfoWrapperCloseButton>
        </InfoWrapperContentText>
      </InfoWrapperContent>
    </InfoWrapper>
  ) : (
    <DualInfoWrapper>
      <InfoWrapper dual>
        <InfoWrapperContent>
          <InfoWrapperContentText mode="heat">
            <InfoWrapperTemperature mode="heat">{desiredHeatTemperature}</InfoWrapperTemperature>
          </InfoWrapperContentText>
        </InfoWrapperContent>
      </InfoWrapper>
      <InfoWrapper dual>
        <InfoWrapperContent>
          <InfoWrapperContentText mode="cool">
            <InfoWrapperTemperature mode="cool">{desiredCoolTemperature}</InfoWrapperTemperature>
          </InfoWrapperContentText>
        </InfoWrapperContent>
      </InfoWrapper>
    </DualInfoWrapper>
  );

  const renderInfoWrapper = () => (
    <InfoWrapper>
      <InfoWrapperContent>
        <InfoWrapperContentText key={hvacMode} mode={hvacMode}>
          {hold && hold?.endTime ? (
            <>
              <InfoWrapperTemperature key={hvacMode} mode={hvacMode}>
                {desiredTemperature}
              </InfoWrapperTemperature>
              until&nbsp;
              {hold.endTime}
              &nbsp;
              <InfoWrapperCloseButton onClick={onResumeProgram}>
                <Remove />
              </InfoWrapperCloseButton>
            </>
          ) : (
            <InfoWrapperTemperature key={hvacMode} mode={hvacMode}>
              {desiredTemperature}
            </InfoWrapperTemperature>
          )}
        </InfoWrapperContentText>
      </InfoWrapperContent>
    </InfoWrapper>
  );
  const renderHoldStatusWrapper = () => (
    <InfoWrapper>
      <InfoWrapperContent>
        <InfoWrapperContentText mode="off">
          <InfoWrapperStatus>{hold.holdClimateRef}</InfoWrapperStatus>
          <InfoWrapperCloseButton onClick={onResumeProgram}>
            <Remove />
          </InfoWrapperCloseButton>
        </InfoWrapperContentText>
      </InfoWrapperContent>
    </InfoWrapper>
  );

  const renderFanInfoWrapper = () => (
    <InfoWrapper>
      <InfoWrapperContent>
        <InfoWrapperContentText mode="off">
          <InfoWrapperFan>
            Fan until&nbsp;
            {hold.endTime}
          </InfoWrapperFan>
          <InfoWrapperCloseButton onClick={onResumeProgram}>
            <Remove />
          </InfoWrapperCloseButton>
        </InfoWrapperContentText>
      </InfoWrapperContent>
    </InfoWrapper>
  );

  const renderContent = () => {
    if (hold?.holdClimateRef && hold.holdClimateRef !== '') {
      return renderHoldStatusWrapper();
    }
    if (hold && hold.fan === 'on' && hold.isTemperatureAbsolute === false) {
      return renderFanInfoWrapper();
    }
    if (hvacMode === 'auto') {
      return renderDualInfoWrapper();
    }
    if (['heat', 'cool'].includes(hvacMode)) {
      return renderInfoWrapper();
    }
    return null;
  };

  return (
    <StyledTemperatureGaugeWrapper>
      <StyledTemperatureGauge role="presentation" aria-label="temperature-gauge" data-testid="temperature-gauge">
        {offline ? (
          <OfflineMessage role="article" aria-label="offline-message" data-testid="offline-message">
            Offline
          </OfflineMessage>
        ) : (
          <>
            <Status>Temp</Status>
            <GaugeTemp>
              <TempNumber role="article" aria-label="temperature-number">
                {currentTemperature}
              </TempNumber>
              <TempType>&deg;F</TempType>
            </GaugeTemp>
            {renderContent()}
          </>
        )}
      </StyledTemperatureGauge>
    </StyledTemperatureGaugeWrapper>
  );
};
