import styled from 'styled-components';

export const StyledThermostatSelectWrapper = styled.div`
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    color: #363636;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: center;
`;
