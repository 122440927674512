import React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { DEFAULT_LOCALE, useLocaleData } from 'components/intl-provider/intl-provider.hooks';
import { selectLocale } from 'store/config/selectors';

interface IntlProviderProps {
  children: React.ReactNode;
}

export const IntlProvider: React.FC<IntlProviderProps> = ({ children }: IntlProviderProps): JSX.Element => {
  const configLocale = useSelector(selectLocale);
  const { locale, messages } = useLocaleData(configLocale.toLowerCase());

  return (
    <ReactIntlProvider messages={messages} locale={locale} defaultLocale={DEFAULT_LOCALE}>
      {children}
    </ReactIntlProvider>
  );
};
