import React from 'react';

import { ArrowDropDown } from 'components/icons/arrow-drop-down';
import { StyledThermostatSelectWrapper } from 'pages/thermostat/thermostat-select/styles';

interface ThermostatSelectProps {
  onClick: () => void;
  thermostatName: string;
}

export const ThermostatSelect = ({ onClick, thermostatName }: ThermostatSelectProps) => (
  <StyledThermostatSelectWrapper onClick={onClick} data-testid="drop-down-thermostat-button">
    {thermostatName}
    <ArrowDropDown />
  </StyledThermostatSelectWrapper>
);
