import { Navigate } from 'react-router';
import React from 'react';

// import { ROOT_PATH } from 'shared/consts';
// import { useViewParams } from 'hooks/use-view-params.hook';

import useUUIDStorageManager from '../../hooks/use-uuid-storage-manager.hook';

// eslint-disable-next-line consistent-return
export const AppRoot = (): JSX.Element => {
  // const params = useViewParams();
  const { userUUID } = useUUIDStorageManager();

  if (userUUID) {
    return <Navigate to="/thermostats" />;
  }

  if (!userUUID) {
    return <Navigate to="/entry" />;
  }
  // if (!params.buildingUuid) {
  //   return <Navigate to="/error" />;
  // }

  // const targetUrl = `${generatePath(ROOT_PATH, { ownerId: params.buildingUuid, ownerType: 'building' })}`;

  // return <Navigate to={targetUrl} />;
};
