import { createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

import { BuildingTheme } from './types';

export const getBuildingTheme = createAsyncAction(
  'GET_BUILDING_THEME_REQUEST',
  'GET_BUILDING_THEME_SUCCESS',
  'GET_BUILDING_THEME_FAILURE',
)<string, Partial<BuildingTheme>, FailureActionPayload>();
