import {
  catchError, filter, map, switchMap, withLatestFrom,
} from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';

import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { getErrorCode } from 'store/utils/get-error-code.util';

import { fetchUiMetadata as fetchUiMetadataAction } from './actions';

export const fetchUiMetadata: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(fetchUiMetadataAction.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => apiClient(state)
    .fetchUiMetadata(payload)
    .pipe(
      map(({ response }) => fetchUiMetadataAction.success(response)),
      catchError((error: Error) => of(fetchUiMetadataAction.failure({ error, errorCode: getErrorCode(error) }))),
    )),
);
