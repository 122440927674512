import { store } from '../store';

interface FetchOptions extends RequestInit {}

export interface ApiResponse<T> {
  data?: T;
  error?: {
    message?: string;
    statusCode?: number;
    errorCode?: string;
  };
}

export const fetchApi = (options?: FetchOptions) => async <T>(url: string): Promise<ApiResponse<T>> => {
  const currentState = store.getState();
  const userToken = currentState.user.token;
  const fetchOptions = options || {};

  fetchOptions.headers = {
    ...fetchOptions.headers,
    Authorization: userToken,
  };

  try {
    const response = await fetch(url, options);

    // Check for a 204 No Content response
    if (response.status === 204) {
      return { data: null };
    }

    const responseData = await response.json();

    if (!response.ok) {
      const errorObj = {
        statusCode: responseData.error.statusCode,
        message: responseData.error.message || `HTTP error! status: ${response.status}`,
        errorCode: responseData.error?.errorCode,
      };
      return { error: errorObj };
    }

    return { data: responseData };
  } catch (error) {
    return { error: { message: 'An unexpected error occurred' } };
  }
};

/*
 * Global configuration for any GET requests should be specified
 */
export const fetchApiData = fetchApi({
  method: 'GET',
});

/*
 * Global configuration for any POST requests should be specified here
 * More specific options must be passed in, in their respective service methods
 */
export const postApiData = (options: FetchOptions) => fetchApi({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  ...options,
});
