import React from 'react';
import { useIntl } from 'react-intl';

import { Info } from 'components/icons';

import { StyledAlert } from './style';

interface AlertProps {
    textId: string
    variant?: 'icon'
    name: string

}

export const Alert = ({
  textId, variant, name,
}: AlertProps) => {
  const intl = useIntl();
  return (
    <StyledAlert role="status" aria-label={name}>
      {variant && <Info />}
      {intl.formatMessage({ id: textId, defaultMessage: textId })}
    </StyledAlert>
  );
};
