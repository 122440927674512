import React, {
  createContext, useContext, useState, useMemo, ReactNode,
} from 'react';

import { ThermostatData } from '../types/thermostatData';

export interface ThermostatContextType {
  thermostats: ThermostatData[];
  selectedThermostat: ThermostatData | null;
  setSelectedThermostat: (thermostat: ThermostatData | null) => void;
  setThermostats: (thermostats: ThermostatData[]) => void;
}

export const ThermostatContext = createContext<ThermostatContextType | undefined>(undefined);

interface ThermostatProviderProps {
  children: ReactNode;
}

export const ThermostatProvider = ({ children }: ThermostatProviderProps) => {
  const [thermostats, setThermostats] = useState<ThermostatData[]>([]);
  const [selectedThermostat, setSelectedThermostat] = useState<ThermostatData | null>(null);

  const value = useMemo(
    () => ({
      thermostats,
      selectedThermostat,
      setSelectedThermostat,
      setThermostats,
    }),
    [thermostats, selectedThermostat],
  );

  return <ThermostatContext.Provider value={value}>{children}</ThermostatContext.Provider>;
};

export const useThermostatContext = () => {
  const context = useContext(ThermostatContext);
  if (context === undefined) {
    throw new Error('useThermostatContext must be used within a ThermostatProvider');
  }
  return context;
};
