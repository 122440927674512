import React from 'react';

import {
  StyledSettingSelectButton,
  StyledSettingsButtonText, StyledSettingsButtonTitle,
} from 'components/button/settings-select-button/style';

interface SettingsSelectButtonProps {
    name: string
    onClick: () => void;
    testId?: string;
    title: string;
    text: string
    icon?: React.ReactNode;
}

export const SettingsSelectButton = ({
  onClick, title, text, name, testId, icon,
}:SettingsSelectButtonProps) => (
  <StyledSettingSelectButton onClick={onClick} aria-label={name} data-testid={testId}>
    <StyledSettingsButtonTitle>{title}</StyledSettingsButtonTitle>
    <StyledSettingsButtonText>
      {text}
      {icon}
    </StyledSettingsButtonText>

  </StyledSettingSelectButton>
);
