import styled from 'styled-components';

interface StyledCallToActionButtonInterface {
    variant?: 'primary' | 'modal'
    active?: boolean;
}
export const StyledCallToActionButton = styled.button<StyledCallToActionButtonInterface>`
width: ${(props) => {
    switch (props.variant) {
    case 'modal':
      return '100%';
    default:
      return '160px';
    }
  }};
    padding: 5px 16px;
    margin: auto;
    border: none;
    border-radius:4px;
    color: #fff;
    background-color: #00263E;
    font-weight: 500;
    font-size: 16px;
    line-height:22px;
    height: ${(props) => {
    switch (props.variant) {
    case 'modal':
      return '54px';
    default:
      return '32px';
    }
  }};

`;
