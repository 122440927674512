import React from 'react';

import { Wrapper } from 'pages/miniapp-sdk-demo/miniapp-sdk-demo.styles';
import { MiniappSdkMethodDemo } from 'pages/miniapp-sdk-demo/miniapp-sdk-method-demo';
import { MiniappSdkStorageGetDemo } from 'pages/miniapp-sdk-demo/miniapp-sdk-storage-get-demo';
import { MiniappSdkStorageSetDemo } from 'pages/miniapp-sdk-demo/miniapp-sdk-storage-set-demo';
import { MiniappSdkTokenizeCardDemo } from 'pages/miniapp-sdk-demo/miniapp-sdk-tokenize-card-demo';
import { MiniappSdkShowNativePaymentAuth } from 'pages/miniapp-sdk-demo/miniapp-sdk-show-native-payment-auth';

export const MiniappSdkDemoPage = (): JSX.Element => (
  <Wrapper>
    <MiniappSdkMethodDemo actionGroup="identity" action="getJWT" eventName="getJWTResponse" />
    <MiniappSdkMethodDemo actionGroup="identity" action="getUser" eventName="getUserResponse" />
    <MiniappSdkMethodDemo actionGroup="identity" action="getBuilding" eventName="getBuildingResponse" />
    <MiniappSdkMethodDemo actionGroup="identity" action="getBuildings" eventName="getBuildingsResponse" />
    <MiniappSdkMethodDemo actionGroup="host" action="getInfo" eventName="getHostInfoResponse" />
    <MiniappSdkMethodDemo actionGroup="payments" action="hideNativePayments" eventName="hideNativePaymentsResponse" />
    <MiniappSdkMethodDemo actionGroup="payments" action="getPaymentMethods" eventName="getPaymentMethodsResponse" />
    <MiniappSdkMethodDemo actionGroup="header" action="showHeader" eventName="showHeaderResponse" />
    <MiniappSdkMethodDemo actionGroup="header" action="hideHeader" eventName="hideHeaderResponse" />
    <MiniappSdkTokenizeCardDemo />
    <MiniappSdkStorageGetDemo />
    <MiniappSdkStorageSetDemo />
    <MiniappSdkShowNativePaymentAuth />
  </Wrapper>
);
