import { useIntl } from 'react-intl';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';

import { Modal } from 'components/modal';
import { selectErrorNotifications } from 'store/errors/selectors';
import { hideErrorNotifications } from 'store/errors/actions';
import { getErrorDetails } from 'utils/get-error-details';
import { ErrorType } from 'shared/consts/error-translations';

export const ErrorModal = (): JSX.Element | null => {
  const { formatMessage, messages } = useIntl();
  const errors = useSelector(selectErrorNotifications);
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(hideErrorNotifications());
  }, [dispatch]);

  const { titleTranslationId, descriptionTranslationId, buttonTextTranslationId } = getErrorDetails(
    errors,
    messages,
    ErrorType.ERRORS,
  );

  if (!errors.length) {
    return null;
  }

  return (
    <Modal onClose={handleClose} variant="small" hideMobileCloseButton>
      <ErrorNotification
        title={formatMessage({ id: titleTranslationId })}
        description={formatMessage({ id: descriptionTranslationId })}
        buttonText={formatMessage({ id: buttonTextTranslationId })}
        onClick={handleClose}
      />
    </Modal>
  );
};
