export const statusOptions = [
  {
    id: 1,
    name: 'thermostat-status-home',
    textId: 'thermostatStatuses.home',
    category: 'status',
    selected: true,
    requireSaveClick: true,
  },
  {
    id: 2,
    name: 'thermostat-status-work',
    textId: 'thermostatStatuses.work',
    category: 'status',
    selected: false,
    requireSaveClick: true,
  },
  {
    id: 3,
    name: 'thermostat-status-sleep',
    textId: 'thermostatStatuses.sleep',
    category: 'status',
    selected: false,
    requireSaveClick: true,
  },
  {
    id: 4,
    name: 'thermostat-status-awake',
    textId: 'thermostatStatuses.awake',
    category: 'status',
    selected: false,
    requireSaveClick: true,
  },
];
