import { useIntl } from 'react-intl';
import React, { useCallback } from 'react';

import { EntryText, Header } from 'pages/entry/styles';
import { ComponentsWrapper, DisplayWrapper } from 'components/wrapper/styles';
import { CallToActionButton } from 'components/button/call-to-action-button';
import { Alert } from 'components/alert/alert';

export const Entry = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const ecoBeeAuthorizationUrl = `${process.env.REACT_APP_ECOBEE_API_URL}/ecobee-auth/login`;

  const redirectToEcoBee = useCallback(() => {
    window.location.href = ecoBeeAuthorizationUrl;
  }, [ecoBeeAuthorizationUrl]);

  return (
    <DisplayWrapper>
      <Alert variant="icon" name="entry-status" textId="alert.info" />
      <ComponentsWrapper>
        <Header data-testid="entry-header">{formatMessage({ id: 'temperature' })}</Header>
        <EntryText data-testid="entry-text">{formatMessage({ id: 'entry.defaultText' })}</EntryText>
        <CallToActionButton name="entry-connect" textId="cta.connect" onClick={redirectToEcoBee} />
      </ComponentsWrapper>
    </DisplayWrapper>
  );
};
