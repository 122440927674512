import styled from 'styled-components';

interface StyledTemperatureGaugeProps {
  mode: 'heat' | 'cool' | 'auto' | 'off';
}
interface StyledTemperatureGaugeWrapperProps {
  dual?: boolean;
}
export const StyledTemperatureGaugeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center
`;

export const StyledTemperatureGauge = styled.div`
  width: 222px;
  height: 222px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 30px rgba(105, 110, 114, 0.3);
`;

export const Segment = styled.div`
  text-align: center;
`;

export const Status = styled.span`
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #696E72;
`;

export const GaugeTemp = styled.span`
  display: flex;
  margin-left: 18px;
`;

export const TempNumber = styled.span`
  font-family: Roboto;
  font-weight: 600;
  font-size: 56px;
  text-align: center;
  color: #2D3338;
  margin: 5px;
`;

export const TempType = styled.span`
  font-family: Roboto;
  font-weight: 300;
  font-size: 20px;
  color: #696E72;
`;

export const TempTypeMessage = styled(TempType)`
  font-size: 8px;
`;

export const InfoWrapper = styled.div<StyledTemperatureGaugeWrapperProps>`
  font-family: Roboto;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  text-align: center;
  color: #696E72;
  padding: ${({ dual }) => (dual ? '8px 16px' : '8px')};
  border-radius: 100px;
  border: 1px #EAECEE solid;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex
`;

export const DualInfoWrapper = styled.div`
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  display: inline-flex;
  // InfoWrapperTemperature set padding: 8px 16px
`;

export const InfoWrapperContent = styled.div`
  justify-content: center;
  align-items: flex-start;
  display: flex
`;

export const InfoWrapperContentText = styled.div<StyledTemperatureGaugeProps>`
  text-align: center;
  color: ${({ mode }) => (mode === 'heat' ? '#D52465' : '#0760AA')};
  font-size: 14px;
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  word-wrap: break-word
`;

export const InfoWrapperTemperature = styled.span<StyledTemperatureGaugeProps>`
  text-align: center;
  color: ${({ mode }) => (mode === 'heat' ? '#D52465' : '#0760AA')};
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  position: relative;
  padding-right: 10px;
  &::after {
    content: '°F';
    position: absolute;
    top: -1px;
    right: 0;
    font-size: 8px;
    font-weight: 300;
  }
`;
export const InfoWrapperStatus = styled.span`
  text-align: center;
  padding-right: 8px;
  text-transform: capitalize;
`;

export const InfoWrapperFan = styled.span`
  text-align: center;
  padding-right: 8px;
  color: #696E72;
`;

export const InfoWrapperCloseButton = styled.button`
  width: 16px;
  height: 16px;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #696E72;
  background-color: transparent;

  img {
    width: 8px;
    height: 8px;
    flex-shrink: 0;
  }
`;
export const OfflineMessage = styled.span`
  font-family: Roboto;
  font-weight: 600;
  text-align: center;
  font-size: 36px;
  text-transform: uppercase;
  color: #696E72;

`;
