import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import {
  DisplayTemperature,
  FarenheitSign,
  OfflineThermostat,
  ShowThermostat,
  TemperatureDegrees,
  ThermostatNameWrapper,
  ThermostatWrapper,
} from 'pages/display-thermostats/styles';

export interface ThermostatProps {
  name: string;
  temperature: number | null;
  isActive: boolean;
  onClick: () => void;
}

export const ThermostatMenuItem = ({
  name, temperature, isActive, onClick,
}: ThermostatProps): JSX.Element => {
  const [thermostatTemperature] = useState(temperature);
  const intl = useIntl();

  return isActive ? (
    <ThermostatWrapper onClick={onClick}>
      <ThermostatNameWrapper>{name}</ThermostatNameWrapper>
      <DisplayTemperature>
        <TemperatureDegrees>{thermostatTemperature}</TemperatureDegrees>
        <FarenheitSign>&#x2109;</FarenheitSign>
      </DisplayTemperature>
      <ShowThermostat size="xs" />
    </ThermostatWrapper>
  ) : (
    <ThermostatWrapper>
      <ThermostatNameWrapper>{name}</ThermostatNameWrapper>
      <OfflineThermostat>{intl.formatMessage({ id: 'offline_termostat' })}</OfflineThermostat>
    </ThermostatWrapper>
  );
};
