import { fanOptions } from '../constants/fanOptions';
import { modeOptions } from '../constants/modeOptions';
import { Climate, ThermostatData } from '../types/thermostatData';
import { Option } from '../types/option';
import { ThermostatPageSettings } from '../types/thermostatPageSettings';
import { ApiResponseSingleThermostatData } from '../types/apiResponseSingleThermostatData';
import { ThermostatEvent } from '../types/thermostatEvent';
import { ThermostatSettingsList } from '../types/thermostatSettingsList';
import { TemperatureSliderProps } from '../types/thermostatThemperatureSliderProps';

interface TransformedThermostatData {
  settings: ThermostatPageSettings;
  settingsList:ThermostatSettingsList;
}
interface TemperatureControlParams {
  holdType: 'nextTransition';
  heatHoldTemp?: number;
  coolHoldTemp?: number;
}
const mapThermostatToOption = (thermostat: ThermostatData, currentThermostatId: string): Option => ({
  id: thermostat.identifier,
  name: thermostat.name,
  textId: thermostat.name,
  category: 'thermostat',
  selected: thermostat.identifier === currentThermostatId,
  requireSaveClick: false,
});

const statusOrder: string[] = ['Home', 'Away', 'Sleep'];
const mapStatusToOption = (climates: Climate[], currentClimateRef: string): Option[] => {
  const options = climates.map((climate) => ({
    id: climate.climateRef,
    name: `thermostat-status-${climate.climateRef.toLowerCase()}`,
    textId: climate.name,
    category: 'status',
    selected: climate.climateRef === currentClimateRef,
    requireSaveClick: true,
  }));

  // Sort the options based on the predefined order
  options.sort((a, b) => {
    const indexA = statusOrder.indexOf(a.textId);
    const indexB = statusOrder.indexOf(b.textId);

    if (indexA !== -1 || indexB !== -1) {
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    }

    return 0;
  });
  return options.reverse();
};

const mapHvacModeToOption = (hvacMode: string): Option[] => modeOptions.map((option) => ({
  ...option,
  selected: (
    (hvacMode === 'heat' && option.name === 'tile-heat')
    || (hvacMode === 'cool' && option.name === 'tile-cool')
    || (hvacMode === 'off' && option.name === 'tile-off')
    || (hvacMode === 'auto' && option.name === 'tile-auto')
  ),
}));

const formatEndDate = (event: ThermostatEvent) => {
  const start = new Date(`${event.startDate}T${event.startTime}`).getTime();
  const end = new Date(`${event.endDate}T${event.endTime}`).getTime();

  if (event.fan === 'on' && end - start > 7200000) {
    return 'indefinite';
  }
  const endTimeDate = new Date(`${event.endDate}T${event.endTime}`);
  return endTimeDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }).toLowerCase();
};

const mapHoldData = (events: ThermostatEvent[]) => {
  const holdEvent = events.find((event) => event.type === 'hold');
  if (holdEvent) {
    return {
      endTime: formatEndDate(holdEvent),
      holdClimateRef: holdEvent.holdClimateRef,
      fan: holdEvent.fan,
      coolHoldTemp: holdEvent.coolHoldTemp,
      heatHoldTemp: holdEvent.heatHoldTemp,
      isTemperatureAbsolute: holdEvent.isTemperatureAbsolute,
    };
  }
  return null;
};

const getFanOptionId = (events: ThermostatEvent[]): number => {
  // Otherwise, calculate hold time
  const fanEvent = events.find((event) => event.type === 'hold' && event.fan === 'on');
  if (!fanEvent) return 0;

  const start = new Date(`${fanEvent.startDate}T${fanEvent.startTime}`);
  const end = new Date(`${fanEvent.endDate}T${fanEvent.endTime}`);
  const holdTime = (end.getTime() - start.getTime()) / (1000 * 60); // Convert milliseconds to minutes

  // Determine fanOptionId based on holdTime
  if (holdTime <= 16) return 1; // 15-min-fan
  if (holdTime <= 31) return 2; // 30-min-fan
  if (holdTime <= 46) return 3; // 45-min-fan
  if (holdTime <= 61) return 4; // 1-hour-fan
  if (holdTime <= 121) return 5; // 2-hour-fan
  return 6; // tile-indefinite for any holdTime greater than 120
};

export const transformApiResponse = (
  apiResponse: ApiResponseSingleThermostatData,
  thermostats: ThermostatData[],
  currentThermostat: ThermostatData,
): TransformedThermostatData => {
  const thermostatOptions = thermostats.map(
    (thermostat) => mapThermostatToOption(thermostat, currentThermostat.identifier),
  );
  const modeOptionsWithSelected = mapHvacModeToOption(apiResponse.thermostat.settings.hvacMode);

  const fanOptionId = getFanOptionId(apiResponse.thermostat.events);

  const holdData = mapHoldData(apiResponse.thermostat.events);

  // Determine active status based on holdClimateRef if it's set and not empty
  const activeStatusRef = holdData && holdData.holdClimateRef && holdData.holdClimateRef !== ''
    ? holdData.holdClimateRef
    : apiResponse.thermostat.program.currentClimateRef;

  const statusOptionsWithSelected = mapStatusToOption(
    apiResponse.thermostat.program.climates,
    activeStatusRef,
  );

  const updatedFanOptions = fanOptions.map((option) => ({
    ...option,
    selected: option.id === fanOptionId,
  }));

  return {
    settingsList: {
      thermostat: thermostatOptions,
      fan: updatedFanOptions,
      status: statusOptionsWithSelected.reverse(),
      mode: modeOptionsWithSelected,
    },
    settings: {
      thermostat: thermostatOptions.find((option) => option.selected),
      fan: updatedFanOptions.find((option) => option.id === fanOptionId),
      status: statusOptionsWithSelected.find((option) => option.selected),
      mode: modeOptionsWithSelected.find((option) => option.selected),
      hold: mapHoldData(apiResponse.thermostat.events),
    },
  };
};

export const addMinutesAndFormat = (thermostatTime: string | number | Date, additionalMinutes: number) => {
  const thermostatDateTime = new Date(thermostatTime);
  thermostatDateTime.setMinutes(thermostatDateTime.getMinutes() + additionalMinutes);

  const formattedDate = thermostatDateTime.toISOString().split('T')[0];
  const formattedTime = thermostatDateTime.toTimeString().split(' ')[0];

  return { formattedDate, formattedTime };
};

export const generatePayloadFromOption = (option: Option, thermostatTime: string | number | Date) => {
  let payload = {};
  let formattedDate = null;
  let formattedTime = null;

  switch (option.category) {
  case 'status':
    payload = {
      functions: [
        { type: 'setHold', params: { holdType: 'nextTransition', holdClimateRef: option.id } },
      ],
    };
    break;
  case 'mode':
    payload = { thermostat: { settings: { hvacMode: option.name.split('-')[1] } } };
    break;
  case 'fan':
    if (option.minutes) {
      const result = addMinutesAndFormat(thermostatTime, option.minutes);
      formattedDate = result.formattedDate;
      formattedTime = result.formattedTime;
    }

    payload = {
      functions: [
        {
          type: 'setHold',
          params: {
            holdType: option.minutes ? 'dateTime' : 'indefinite',
            ...(option.minutes && {
              endTime: formattedTime,
              endDate: formattedDate,
            }),
            fan: 'on',
          },
        }],
    };
    break;
  default:
    break;
  }

  return payload;
};

export const generateTemperaturePayload = (
  heatTemperature: number,
  coolTemperature: number,
  desiredTemperature: number,
  hvacMode: TemperatureSliderProps['type'],
) => {
  const temp = hvacMode === 'auto'
    ? { heatHoldTemp: heatTemperature * 10, coolHoldTemp: coolTemperature * 10 }
    : { heatHoldTemp: desiredTemperature * 10, coolHoldTemp: desiredTemperature * 10 };

  return {
    functions: [
      {
        type: 'setHold',
        params: {
          holdType: 'nextTransition',
          ...temp,
        } as TemperatureControlParams,
      },
    ],
  };
};
