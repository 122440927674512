import styled from 'styled-components';

export const DisplayWrapper = styled.div`
    width: 100%;
    max-width: 390px;
    margin: auto;
`;

export const ComponentsWrapper = styled.div`
    max-width: 358px;
    min-height: 100px;
    border-radius: 12px;
    padding: 20px;
    gap: 16px;
    background-color: #E9F2F7;
`;
