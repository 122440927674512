import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useBooleanState } from '@hqo/react-components-library';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import { useIntl } from 'react-intl';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';

import { TemperatureGauge } from 'pages/thermostat/temperature-gauge/temperature-gauge';
import { TemperatureSlider } from 'pages/thermostat/temperature-slider/temperature-slider';
import { TemperatureSettingsSelect } from 'pages/thermostat/temperature-settings-select/temperature-settings-select';
import { ThermostatSelect } from 'pages/thermostat/thermostat-select';
import { Alert } from 'components/alert/alert';
import SwipeModalContent from 'components/modal/swipe-modal/swipe-modal-content';
import { CallToActionButton } from 'components/button/call-to-action-button';
import { StyledThermostatWrapper, ThermostatCloseButton } from 'pages/thermostat/style';

import { Option } from '../../types/option';
import { useThermostatSettings } from '../../hooks/use-thermostat-settings.hook';
import { fetchSingleThermostatData, resumeProgram, updateThermostatData } from '../../services/thermostatService';
import useUUIDStorageManager from '../../hooks/use-uuid-storage-manager.hook';
import { Container } from '../../hocs/shared-styles';
import { useThermostatContext } from '../../contexts/ThermostatContext';
import { generateTemperaturePayload, transformApiResponse } from '../../utils/termostatUtils';
import { calculateThermostatLimits } from '../../utils/thermostatTemperatureUtils';
import { TemperatureSliderProps } from '../../types/thermostatThemperatureSliderProps';
import { Close } from '../../components/icons/close';
import { LoaderLine } from '../../components/loader-line/LoaderLine';

type OptionsMapType = { [key: number]: Option[] };

function useModalLogic() {
  const [saveOption, setSaveOption] = useState(true);
  const [modalContent, setModalContent] = useState(0);
  const [title, setTitle] = useState('');
  return {
    saveOption,
    setSaveOption,
    modalContent,
    setModalContent,
    title,
    setTitle,
  };
}

function getHvacMode(mode: string): TemperatureSliderProps['type'] {
  const validModes: TemperatureSliderProps['type'][] = ['cool', 'heat', 'auto', 'off'];
  if (validModes.includes(mode as TemperatureSliderProps['type'])) {
    return mode as TemperatureSliderProps['type'];
  }
  return 'auto';
}

export const Thermostat = (): JSX.Element => {
  const refreshInterval = 60000;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showRefreshing, setShowRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const { selectedThermostat, setSelectedThermostat, thermostats } = useThermostatContext();
  const modalRef = useRef(null);
  const intl = useIntl();
  const { value: isModalOpened, toggle: toggleModalOpened } = useBooleanState(false);

  const [transformedData, setTransformedData] = useState(null);
  const {
    saveOption, setSaveOption, modalContent, setModalContent, title, setTitle,
  } = useModalLogic();
  const [currentTemperature, setCurrentTemperature] = useState(null);
  const [desiredTemperature, setDesiredTemperature] = useState(null);
  const [desiredCoolTemperature, setDesiredCoolTemperature] = useState(0);
  const [desiredHeatTemperature, setDesiredHeatTemperature] = useState(100);
  const { userUUID } = useUUIDStorageManager();
  const navigate = useNavigate();

  const fetchThermostats = useCallback(async () => {
    try {
      const response = await fetchSingleThermostatData(userUUID, selectedThermostat.identifier);
      if (userUUID && response.error && response.error.statusCode === 401) {
        navigate('/entry', { replace: true });
        return;
      }
      if (response.error) {
        setError(response.error.message ?? 'notifications.errors.unableToFetch.title');
      } else {
        setSelectedThermostat(response.data.thermostat);
        setError(null);
        setDesiredHeatTemperature(Math.round(response.data.thermostat.runtime.desiredHeat / 10));
        setDesiredCoolTemperature(Math.round(response.data.thermostat.runtime.desiredCool / 10));
        // Transform the response data
        if (response.data.thermostat.settings.hvacMode === 'heat') {
          setDesiredTemperature(Math.round(response.data.thermostat.runtime.desiredHeat / 10));
        } else if (response.data.thermostat.settings.hvacMode === 'cool') {
          setDesiredTemperature(Math.round(response.data.thermostat.runtime.desiredCool / 10));
        } else {
          setDesiredTemperature(0);
        }
        const transformed = transformApiResponse(response.data, thermostats, selectedThermostat);
        setTransformedData(transformed); // Update the context or state with transformed data
        setCurrentTemperature(Math.round(response.data.thermostat.runtime.actualTemperature / 10));
      }
    } catch (err) {
      setError('notifications.errors.unableToFetch.title');
    } finally {
      setIsRefreshing(false);
      setShowRefreshing(false);
    }
  }, [userUUID, thermostats, selectedThermostat]);

  const intervalRef = useRef(null);
  useEffect(() => {
    if (!selectedThermostat?.identifier) {
      navigate('/thermostats', { replace: true });
    }
    fetchThermostats();
    intervalRef.current = setInterval(fetchThermostats, refreshInterval);

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (isRefreshing) {
      fetchThermostats();
    }
  }, [isRefreshing]);

  const closeButtonHandler = useCallback(() => {
    navigate('/thermostats', { replace: true });
  }, []);

  const {
    settings,
    settingsList,
    handleOptionSelect,
    handleSaveClick,
  } = useThermostatSettings(
    toggleModalOpened,
    transformedData,
    userUUID,
    selectedThermostat,
    setIsRefreshing,
    setShowRefreshing,
    thermostats,
    setSelectedThermostat,
  );

  const getModalOptions = useCallback(() => {
    const optionsMap: OptionsMapType = {
      1: settingsList.mode,
      2: settingsList.fan,
      3: settingsList.status,
      4: settingsList.thermostat,
    };
    return optionsMap[modalContent] || [];
  }, [modalContent, settingsList]);

  const openModeModal = useCallback(() => {
    setSaveOption(true);
    setTitle('Mode');
    setModalContent(1);
    toggleModalOpened();
  }, []);

  const openFanModal = useCallback(() => {
    setSaveOption(true);
    setTitle('Set Fan Timer');
    setModalContent(2);
    toggleModalOpened();
  }, []);

  const openStatusModal = useCallback(() => {
    setSaveOption(true);
    setTitle('Status');
    setModalContent(3);
    toggleModalOpened();
  }, []);

  const openThermostatModal = useCallback(() => {
    setSaveOption(false);
    setTitle('Select Thermostat');
    setModalContent(4);
    toggleModalOpened();
  }, []);

  if (selectedThermostat?.runtime?.connected === false) {
    setError('thermostat.offline');
  }

  const handleDragEnd = useCallback(async () => {
    if (!userUUID || !selectedThermostat?.identifier) {
      return;
    }
    const payload = generateTemperaturePayload(
      desiredHeatTemperature,
      desiredCoolTemperature,
      desiredTemperature,
      getHvacMode(selectedThermostat.settings.hvacMode),
    );

    try {
      setShowRefreshing(true);
      const response = await updateThermostatData(userUUID, selectedThermostat.identifier, payload);
      if (response.error) {
        setError(response.error.message ?? 'Error updating thermostat data, please try again later');
      }
    } catch {
      setError('Error updating thermostat data, please try again later');
    } finally {
      setTimeout(
        () => {
          setIsRefreshing(true);
        },
        1000,
      );
    }
  }, [userUUID, selectedThermostat, desiredHeatTemperature, desiredCoolTemperature, desiredTemperature]);

  const handleResumeProgram = useCallback(async () => {
    setShowRefreshing(true);
    await resumeProgram(selectedThermostat.identifier, userUUID);
    setTimeout(
      () => {
        setIsRefreshing(true);
      },
      1000,
    );
  }, [selectedThermostat]);

  if (!settings?.thermostat) {
    return (
      <Container isLoading>
        <Spinner size="10em" color={defaultTheme.colors.$primary} />
      </Container>
    );
  }
  let fanTile;

  if (settings.fan?.duration) {
    fanTile = `${settings.fan.duration} ${intl.formatMessage({
      id: settings.fan.textId,
    })}`;
  } else if (settings.fan?.id === 6 && settings.fan?.category === 'fan') {
    fanTile = intl.formatMessage({
      id: settings.fan.textId,
    });
  } else {
    fanTile = intl.formatMessage({
      id: 'tile.auto',
    });
  }

  return (
    <StyledThermostatWrapper>
      {(isRefreshing || showRefreshing) && <LoaderLine />}
      <ThermostatCloseButton onClick={closeButtonHandler}>
        <Close />
      </ThermostatCloseButton>
      <ThermostatSelect onClick={openThermostatModal} thermostatName={settings.thermostat.textId} />
      <TemperatureGauge
        selectedThermostat={selectedThermostat}
        currentTemperature={currentTemperature}
        desiredTemperature={desiredTemperature}
        desiredCoolTemperature={desiredCoolTemperature}
        desiredHeatTemperature={desiredHeatTemperature}
        hvacMode={getHvacMode(selectedThermostat.settings.hvacMode)}
        hold={settings.hold}
        onResumeProgram={handleResumeProgram}
      />
      {['heat', 'cool', 'auto'].includes(selectedThermostat.settings.hvacMode) ? (
        <TemperatureSlider
          coolTemperature={desiredCoolTemperature}
          heatTemperature={desiredHeatTemperature}
          setHeatTemperature={setDesiredHeatTemperature}
          setCoolTemperature={setDesiredCoolTemperature}
          type={getHvacMode(selectedThermostat.settings.hvacMode)}
          temperature={desiredTemperature}
          setTemperature={setDesiredTemperature}
          limits={calculateThermostatLimits(selectedThermostat)}
          onDragEnd={handleDragEnd}
        />
      ) : null}
      <TemperatureSettingsSelect
        openMode={openModeModal}
        openFan={openFanModal}
        openStatus={openStatusModal}
        mode={intl.formatMessage({ id: settings.mode.textId })}
        fan={fanTile}
        status={settings.hold?.holdClimateRef
          ? intl.formatMessage({ id: 'tile.hold' })
          : intl.formatMessage({ id: settings.status?.textId, defaultMessage: settings.status?.textId })}
      />
      {error && <Alert variant="icon" name="thermostats-status-error" textId={error} />}
      {isModalOpened && (
        <SwipeModal
          content={<SwipeModalContent options={getModalOptions()} onOptionSelect={handleOptionSelect} />}
          onClose={toggleModalOpened}
          hasBackground={false}
          isContentSlidable
          reverseAnimation={false}
          ref={modalRef}
          className="swipe-modal-content"
          title={title}
          footerContent={
            saveOption && (
              <CallToActionButton name="tile-save" onClick={handleSaveClick} variant="modal" textId="tile.save" />
            )
          }
        />
      )}
    </StyledThermostatWrapper>
  );
};
