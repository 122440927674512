import React from 'react';

import { SettingsSelectButton } from 'components/button/settings-select-button/settings-select-button';
import { StyledTemperatureSettingsWrapper } from 'pages/thermostat/temperature-settings-select/styles';
import { Fire } from 'components/icons/fire';
import { Snowflake } from 'components/icons/snowflake';

interface TemperatureSettingsSelectProps {
  openMode: () => void;
  openFan: () => void;
  openStatus: () => void;
  mode: string;
  fan: string;
  status: string;
}

const getModeIcon = (mode: string) => {
  if (mode === 'Heat') {
    return <Fire />;
  } if (mode === 'Cool') {
    return <Snowflake />;
  }
  return undefined;
};

export const TemperatureSettingsSelect = ({
  openFan, openMode, openStatus, mode, fan, status,
}:TemperatureSettingsSelectProps) => (

  <StyledTemperatureSettingsWrapper>
    <SettingsSelectButton
      title="Status"
      text={status}
      name="settings-status"
      testId="settings-status"
      onClick={openStatus}
    />
    <SettingsSelectButton
      title="Mode"
      text={mode}
      name="settings-mode"
      testId="settings-mode"
      onClick={openMode}
      icon={getModeIcon(mode)}
    />
    <SettingsSelectButton
      title="Fan"
      text={fan}
      name="settings-fan"
      testId="settings-fan"
      onClick={openFan}
    />
  </StyledTemperatureSettingsWrapper>
);
