import { createSelector } from 'reselect';

import { RootState } from 'store/reducer';

import { ErrorsState, ErrorNotification } from './types';

export const errorsSelect = (state: RootState): RootState['errors'] => state.errors;

export const selectErrorNotifications = createSelector(
  errorsSelect,
  (state: ErrorsState): ErrorNotification[] => state.errors,
);

export const selectGenericErrorNotifications = createSelector(
  errorsSelect,
  (state: ErrorsState): ErrorNotification[] => state.genericErrors,
);
