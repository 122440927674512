import { useBooleanState } from '@hqo/react-components-library';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useIntl } from 'react-intl';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { useNavigate } from 'react-router-dom';

import { ComponentsWrapper, DisplayWrapper } from 'components/wrapper/styles';
import { TemperatureHeading, ThermostatSetting } from 'pages/display-thermostats/styles';
import { ThermostatMenuItem } from 'pages/display-thermostats/thermostat-menu-item';
import { ThermostatSettings } from 'pages/thermostat-settings';
import { ThermostatData } from 'types/thermostatData';

import { useThermostatContext } from '../../contexts/ThermostatContext';
import { fetchThermostatData } from '../../services/thermostatService';
import { Alert } from '../../components/alert/alert';
import { Container } from '../../hocs/shared-styles';
import useUUIDStorageManager from '../../hooks/use-uuid-storage-manager.hook';
import { CallToActionButton } from '../../components/button/call-to-action-button';

export const Thermostats = (): JSX.Element => {
  const intl = useIntl();
  const modalRef = useRef(null);
  const { value: isModalOpened, toggle: toggleModalOpened } = useBooleanState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoginRedirect, setShowLoginRedirect] = useState(false);
  const [error, setError] = useState(null);
  const { userUUID } = useUUIDStorageManager();
  const { thermostats, setThermostats, setSelectedThermostat } = useThermostatContext();
  const navigate = useNavigate();

  const fetchThermostats = async () => {
    try {
      const response = await fetchThermostatData(userUUID);
      if (userUUID && response.error && response.error.statusCode === 401) {
        setError('notifications.errors.unableToFetch.title');
        setShowLoginRedirect(true);
      }
      if (response.error) {
        setError(response.error.message ?? 'notifications.errors.unableToFetch.title');
      } else {
        setThermostats(response.data.thermostatList);
      }
    } catch (err) {
      setError('notifications.errors.unableToFetch.title');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchThermostats();
  }, []);

  const handleThermostatSelect = (thermostat: ThermostatData) => () => {
    setSelectedThermostat(thermostat);
    navigate('/thermostat', { replace: true });
  };

  const handleLoginRedirect = useCallback(() => {
    navigate('/entry', { replace: true });
  }, []);

  if (isLoading) {
    return (
      <Container isLoading>
        <Spinner size="10em" color={defaultTheme.colors.$primary} />
      </Container>
    );
  }

  return (
    <DisplayWrapper>
      <ComponentsWrapper>
        <TemperatureHeading>{intl.formatMessage({ id: 'temperature' })}</TemperatureHeading>
        <ThermostatSetting onClick={toggleModalOpened} size="s/m" />

        {error && (
          <Alert variant="icon" name="thermostats-status-error" textId={error} />
        )}

        {showLoginRedirect && (
          <CallToActionButton name="entry-connect" textId="cta.login" onClick={handleLoginRedirect} />
        )}

        {thermostats.map((thermostat) => (
          <ThermostatMenuItem
            key={thermostat.name}
            name={thermostat.name}
            temperature={Math.round(thermostat.runtime.actualTemperature / 10)}
            isActive={thermostat.runtime.connected}
            onClick={handleThermostatSelect(thermostat)}
          />
        ))}
      </ComponentsWrapper>
      {isModalOpened && (
        <SwipeModal
          content={<ThermostatSettings />}
          onClose={toggleModalOpened}
          hasBackground={false}
          isContentSlidable
          reverseAnimation={false}
          ref={modalRef}
          isScrollableContent={false}
          className="swipe-modal-content"
        />
      )}
    </DisplayWrapper>
  );
};
