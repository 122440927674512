import { createAsyncAction } from 'typesafe-actions';

import { Building } from 'store/building/types';
import { FailureActionPayload } from 'store/errors/types';

export const getBuilding = createAsyncAction('GET_BUILDING_REQUEST', 'GET_BUILDING_SUCCESS', 'GET_BUILDING_FAILURE')<
  string,
  Partial<Building>,
  FailureActionPayload
>();
