import { modeOptions } from '../constants/modeOptions';
import { fanOptions } from '../constants/fanOptions';
import { statusOptions } from '../constants/statusOptions';

export const thermostatsDataMock = [
  {
    id: 1,
    name: 'thermostat1',
    textId: 'tile.thermostatMock1',
    category: 'thermostat',
    selected: true,
    requireSaveClick: false,
  },
  {
    id: 2,
    name: 'thermostat2',
    textId: 'tile.thermostatMock2',
    category: 'thermostat',
    selected: false,
    requireSaveClick: false,
  },
];

export const thermostatSettingsDataMock = {
  settings: {
    mode: modeOptions[0],
    fan: fanOptions[0],
    status: statusOptions[0],
    thermostat: thermostatsDataMock[0],
    hold: { },
  },
  settingsList: {
    mode: modeOptions,
    fan: fanOptions,
    status: statusOptions,
    thermostat: thermostatsDataMock,
  },
  selectedOption: {},
};
export const thermostatSettingsEmptyDataMock = {
  settings: {},
  settingsList: {
    mode: modeOptions,
    fan: fanOptions,
    status: statusOptions,
    thermostat: thermostatsDataMock,
  },
  selectedOption: {},
};
