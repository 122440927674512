import { createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';
import { OwnerParams } from 'shared/types';

import { UiMetadata } from './types';

export const fetchUiMetadata = createAsyncAction(
  'ui-metadata/FETCH_REQUEST',
  'ui-metadata/FETCH_SUCCESS',
  'ui-metadata/FETCH_FAILURE',
)<OwnerParams, UiMetadata, FailureActionPayload>();
