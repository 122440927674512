import styled, { keyframes } from 'styled-components';

const lineAnimation = keyframes`
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
`;

export const StyledLoaderLine = styled.div`
  width: 100%;
  height: 3px;
  position: absolute;
  overflow: hidden;
  background-color: #ddd;
  border-radius: 20px;

  &:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    width: 40%;
    background-color: #0760AA;
    animation: ${lineAnimation} 1s linear infinite;
    border-radius: 3px;
  }
`;
