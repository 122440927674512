import { useSelector } from 'react-redux';
import React from 'react';

import { MiniappSdkContext } from 'components/miniapp-sdk-provider/miniapp-sdk.context';
import { useMiniappSdkClient } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';
import { uiMetadata } from 'store/ui-metadata/selectors';

interface MiniappSdkProviderProps {
  children: React.ReactNode;
}

export const MiniappSdkProvider = ({ children }: MiniappSdkProviderProps): JSX.Element => {
  const UiMetadata = useSelector(uiMetadata);
  const appUuid = UiMetadata?.app_uuid;
  const miniappSdkClient = useMiniappSdkClient(appUuid);

  return <MiniappSdkContext.Provider value={miniappSdkClient}>{children}</MiniappSdkContext.Provider>;
};
