import { HqoClientSdk, SdkEvents } from '@hqo/hqo-miniapp-client-sdk';
import React, { useCallback, useState } from 'react';

import { useMiniappSdk, useMiniappSdkEventHandler } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';

import { Card, StyledButton } from './miniapp-sdk-method-demo.styles';

type ActionGroups = keyof Pick<HqoClientSdk, 'host' | 'identity' | 'payments' | 'storage' | 'header'>;

interface MiniappSdkMethodDemoProps<AG extends ActionGroups> {
  actionGroup: AG;
  action: keyof HqoClientSdk[AG];
  eventName: SdkEvents;
  children?: React.ReactNode;
  args?: unknown[];
}

export const MiniappSdkMethodDemo = <AG extends ActionGroups>({
  action,
  actionGroup,
  eventName,
  children,
  args = [],
}: MiniappSdkMethodDemoProps<AG>): JSX.Element => {
  const [eventData, setEventData] = useState(null);
  const client = useMiniappSdk();

  useMiniappSdkEventHandler(
    eventName,
    (data: unknown) => {
      setEventData(JSON.stringify(data, null, 2));
    },
    [setEventData],
  );

  const handleClick = useCallback(() => {
    const emitAction = client[actionGroup][action];

    if (typeof emitAction === 'function') {
      emitAction(...args);
    }
  }, [action, actionGroup, args]);

  return (
    <Card>
      <StyledButton onClick={handleClick} text={`${actionGroup}.${String(action)}`} />
      {children}
      <pre>{eventData}</pre>
    </Card>
  );
};
