/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from 'react';

import { StyledSwipeModalContainer } from 'components/modal/swipe-modal/styles';
import { TileItem } from 'pages/tile-selector/tile-item';

import { Option } from '../../../types/option';

interface SwipeModalContentProps {
  options: Option[];
  onOptionSelect: (option: Option) => void;
}

const SwipeModalContent = ({ options, onOptionSelect }: SwipeModalContentProps) => {
  const [selectedButton, setSelectedButton] = useState<string | number
    | null>(options.find((option) => option.selected)?.id || null);

  const handleTileItemClick = useCallback((option: Option) => {
    setSelectedButton(option.id);
    onOptionSelect(option);
  }, [onOptionSelect]);

  return (
    <StyledSwipeModalContainer>
      {options.map((option) => (
        <TileItem
          key={option.id}
          name={option.name}
          textId={option.textId}
          startIcon={option.startIcon}
          duration={option.duration}
          onClick={() => handleTileItemClick(option)}
          selected={selectedButton === option.id}
        />
      ))}
    </StyledSwipeModalContainer>
  );
};

export default SwipeModalContent;
