import styled from 'styled-components';

interface StyledTemperatureSliderProps {
  value: string | number | readonly string[];
  min: number;
  max: number;
  temperature: string;
}

interface StyledDualTemperatureSliderProps{
  leftMargin : string;
  activeWidth : string;
}
export const StyledTemperatureRangeWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
`;

export const StyledRangedSliderContainer = styled.div`
    width:80%;
    position: relative;
    margin-top: 60px;
    margin-bottom: 20px;
`;
const normalizeValue = (value: number, min: number, max: number) => ((value - min) / (max - min)) * 100;

export const StyledTemperatureSlider = styled.input<StyledTemperatureSliderProps>`
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: linear-gradient(
    to right,
    ${(props) => (props.temperature === 'cool' ? '#0760AA' : '#D52465')} ${(props) => {
  const value = typeof props.value === 'number' ? props.value : parseFloat(props.value as string);
  return Number.isNaN(value) ? '0%' : `${normalizeValue(value, props.min, props.max)}%`;
}},
    #EAECEE ${(props) => {
    const value = typeof props.value === 'number' ? props.value : parseFloat(props.value as string);
    return Number.isNaN(value) ? '100%' : `${normalizeValue(value, props.min, props.max)}%`;
  }}
  );
  outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;

    &:focus {
        opacity: 1;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 30px;
        height: 30px;
        border: 3px solid ${(props) => (props.temperature === 'cool' ? '#0760AA' : '#D52465')};
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        z-index: 3;

    }

    &::-moz-range-thumb {
        width: 30px;
        height: 30px;
        border: 3px solid #2D3338;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        position: relative;
        z-index: 3;

    }
`;

export const StyledDualTemperatureRangeWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 1;

  input[type=range]::-webkit-slider-thumb {
    cursor: pointer;
    pointer-events: all;
    background: white;
    opacity: 1;
  }
  input[type=range]::-moz-range-thumb {
    cursor: pointer;
    pointer-events: all;
    background: white;
    opacity: 1;
  }

  input[type=range] {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
    cursor: default;
    z-index: 2;
  }
`;
export const DualTemperatureButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const DualTemperatureButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

export const DualTemperatureButtonDescription = styled.div`
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #707070;
  margin: 10px 0;
  flex-grow: 1;
`;
export const StyledDualTemperatureSlider = styled.div<StyledDualTemperatureSliderProps>`
  position: absolute;
  height: 3px;
  width: 100%;
  background: none;
  border-radius: 10px;
  z-index: 1;
  background: #EAECEE;

  &::before {
    content: '';
    left: ${(props) => props.leftMargin};
    width: ${(props) => props.activeWidth};
    position: absolute;
    height: 3px;
    background: #2D3338;
    border-radius: 10px;
  }
`;

export const TemperatureControlWrapper = styled.div`
    width: 100%;
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const TemperatureControlContainer = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
`;

export const TemperatureButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

export const TemperatureButtonDescription = styled.span`
    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: #707070;
`;
