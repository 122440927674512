import { ActionType, createReducer } from 'typesafe-actions';

import { BuildingState } from 'store/building/types';
import { ACTION_STATUSES } from 'shared/consts';

import * as actions from './actions';

export const INITIAL_STATE: BuildingState = {
  building: null,
  getBuilding: {
    status: null,
    error: null,
  },
};

const getBuildingRequestHandler = (state: BuildingState): BuildingState => ({
  ...state,
  getBuilding: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getBuildingSuccessHandler = (
  state: BuildingState,
  { payload }: ActionType<typeof actions.getBuilding.success>,
): BuildingState => ({
  ...state,
  building: payload,
  getBuilding: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getBuildingFailureHandler = (
  state: BuildingState,
  { payload }: ActionType<typeof actions.getBuilding.failure>,
): BuildingState => ({
  ...state,
  getBuilding: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const BuildingReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.getBuilding.request, getBuildingRequestHandler)
  .handleAction(actions.getBuilding.success, getBuildingSuccessHandler)
  .handleAction(actions.getBuilding.failure, getBuildingFailureHandler);

export default BuildingReducer;
