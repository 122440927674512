import { TemperatureSliderLimits } from '../types/thermostatThemperatureSliderProps';

type ThermostatRuntime = {
  desiredHeatRange?: number[];
  desiredCoolRange?: number[];
};

type Thermostat = {
  runtime?: ThermostatRuntime;
};

export function calculateThermostatLimits(selectedThermostat: Thermostat): TemperatureSliderLimits {
  const defaultMin = 32;
  const defaultMax = 131;

  let heatMin = defaultMin;
  let heatMax = defaultMax;
  let coolMin = defaultMin;
  let coolMax = defaultMax;

  if (selectedThermostat.runtime?.desiredHeatRange) {
    [heatMin, heatMax] = selectedThermostat.runtime.desiredHeatRange.map((value) => Math.round(value / 10));
  }

  if (selectedThermostat.runtime?.desiredCoolRange) {
    [coolMin, coolMax] = selectedThermostat.runtime.desiredCoolRange.map((value) => Math.round(value / 10));
  }

  const overallMin = Math.min(heatMin, coolMin);
  const overallMax = Math.max(heatMax, coolMax);

  return {
    heat: { min: heatMin, max: heatMax },
    cool: { min: coolMin, max: coolMax },
    overall: { min: overallMin, max: overallMax },
  };
}
