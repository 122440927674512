import React from 'react';
import { useIntl } from 'react-intl';

import { ButtonContentWrapper, StyledButton } from './styles';

interface TileItemProps {
    name: string
    textId?: string
    startIcon?:React.ReactNode
    onClick: () => void;
    selected?: boolean
    duration?: number
}

export const TileItem = ({
  textId, onClick, name, startIcon, selected, duration,
}:TileItemProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledButton selected={selected} aria-label={name} onClick={onClick} type="button">
      <ButtonContentWrapper>
        {startIcon}
        <span>
          {duration && `${duration} `}
          {intl.formatMessage({ id: textId, defaultMessage: textId })}
        </span>
      </ButtonContentWrapper>
    </StyledButton>
  );
};
