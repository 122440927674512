import styled from 'styled-components';

interface StyledButton {
    selected?: boolean;
}

export const StyledButton = styled.button<StyledButton>`
    box-sizing:border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Roboto;
    width: 95%;
    height: 54px;
    padding: 5px 16px;
    margin: 8px 0px;
    border: ${(props) => (props.selected ? '2px solid #037CC2' : 'none')};
    border-radius: 8px;
    cursor: pointer;
    color: #2D3338;
    background-color:${(props) => (props.selected ? '#E9F2F7' : '#fff')};
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    box-shadow: 0px 2px 10px 0px rgba(54,54,54, 0.2);
`;

export const ButtonContentWrapper = styled.span`
    display:flex;
    align-items:center;
`;
