import styled from 'styled-components';

export const StyledAlert = styled.div`
    max-width:358px;
    border:1px solid #F6F7F8;
    border-radius:8px;
    background-color: #fff;
    display:flex;
    padding:8px 16px;
    align-items:center;
    line-height:22px;
    font-size:14px;
    margin: 20px auto;
    gap:12px;
`;
