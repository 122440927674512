import { createSelector } from 'reselect';

import { ConfigState } from 'store/config/types';
import { RootState } from 'store/reducer';

export const configSelector = (state: RootState): ConfigState => state.config;

export const selectLocale = createSelector(configSelector, (state: ConfigState): string => state.locale || 'en');

export const hasToken = createSelector(configSelector, ({ authToken }: RootState['config']): boolean => !!authToken);
