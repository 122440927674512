import { Route, Routes } from 'react-router-dom';
import React from 'react';

import { ErrorPage } from 'pages/error';
import { MiniappEcobeePage } from 'pages/miniapp-ecobee';
import { MiniappSdkDemoPage } from 'pages/miniapp-sdk-demo';
import { Private } from 'components/router/private';
import { AppRoot } from 'components/app-root';
import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';
import { HELLO_PATH, ROOT_PATH } from 'shared/consts';
import { Entry } from 'pages/entry';
import { Thermostats } from 'pages/display-thermostats/display-thermostats';
import { TileSelector } from 'pages/tile-selector';
import { ThermostatSettings } from 'pages/thermostat-settings';
import { Thermostat } from 'pages/thermostat';

import { DefaultRedirect } from './default-redirect';

export const Router: React.FC = (): JSX.Element => (
  <AppContentWrapper>
    <Routes>
      <Route path={ROOT_PATH} element={<Private><MiniappEcobeePage /></Private>} />
      <Route path={HELLO_PATH} element={<AppRoot />} />
      <Route path="/sdk-demo" element={<MiniappSdkDemoPage />} />
      <Route path="/" element={<AppRoot />} />
      <Route path="/entry" element={<Entry />} />
      <Route path="/thermostats" element={<Thermostats />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/tile-selector" element={<TileSelector />} />
      <Route path="/thermostat-settings" element={<ThermostatSettings />} />
      <Route path="/thermostat" element={<Thermostat />} />
      <Route path="/offline-thermostat" element={<Thermostat />} />
      <Route element={<DefaultRedirect />} />
    </Routes>
  </AppContentWrapper>
);
