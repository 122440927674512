import { Modal } from '@hqo/react-components-library/dist/molecules/modals/modal';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import styled, { css } from 'styled-components';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

import { ModalProps } from 'components/modal/modal.interface';

const modalVariants: Record<NonNullable<ModalProps['variant']>, ReturnType<typeof css>> = {
  small: css`
    min-height: 300px;
  `,
  normal: css`
    padding: 32px;
    min-height: 348px;
  `,
  wide: css`
    min-height: 348px;
  `,
};

export const StyledModal = styled(Modal)<{ variant: NonNullable<ModalProps['variant']> }>`
  overflow: auto;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-height: 348px;
    max-height: none;
    padding: 24px 56px;

    margin: 24px auto auto;

    overflow: initial;

    ${({ variant }) => modalVariants[variant]}

    ${mediaMaxWidth(css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      padding: 16px;
      overflow-y: auto;
    `)}
  }
`;

export const StyledMobileModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.universal.white};

  padding: 16px 16px 0;
  overflow-y: auto;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 12px;
  right: 0px;
  padding: 0px 16px;

  color: ${({ theme }): string => theme.colors.fontColor};

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
