import styled from 'styled-components';

export const Header = styled.h2`
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 0px;
`;

export const EntryText = styled.p`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #696e72;
`;
