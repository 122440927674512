import styled from 'styled-components';

export const StyledTemperatureSettingsWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    padding: 10px;
    background-color: #fff;
`;
