import React, { useMemo, useState } from 'react';

import { MiniappSdkDemoInput } from 'pages/miniapp-sdk-demo/miniapp-sdk-demo-input';
import { MiniappSdkMethodDemo } from 'pages/miniapp-sdk-demo/miniapp-sdk-method-demo';

export const MiniappSdkStorageSetDemo = (): JSX.Element => {
  const [storageKey, setStorageKey] = useState('');
  const [storageValue, setStorageValue] = useState('');
  const args = useMemo(() => [storageKey, storageValue], [storageKey, storageValue]);

  return (
    <MiniappSdkMethodDemo actionGroup="storage" action="set" eventName="setStorageResponse" args={args}>
      <MiniappSdkDemoInput
        value={storageKey}
        onChange={setStorageKey}
        placeholder="Storage Key"
        dataTestId="storageSetKey"
      />
      <MiniappSdkDemoInput
        value={storageValue}
        onChange={setStorageValue}
        placeholder="Storage Value"
        dataTestId="storageSetValue"
      />
    </MiniappSdkMethodDemo>
  );
};
