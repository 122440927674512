import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { debounce } from '@material-ui/core';

import {
  StyledRangedSliderContainer,
  StyledTemperatureSlider,
  StyledTemperatureRangeWrapper,
  StyledDualTemperatureRangeWrapper,
  StyledDualTemperatureSlider,
} from 'pages/thermostat/temperature-slider/styles';

import { TemperatureSliderProps } from '../../../types/thermostatThemperatureSliderProps';

const debouncedOnDragEnd = debounce((callback) => {
  callback();
}, 1000);
export const TemperatureSlider = ({
  temperature,
  setTemperature,
  coolTemperature,
  heatTemperature,
  setCoolTemperature,
  setHeatTemperature,
  type,
  limits,
  onDragEnd,
}: TemperatureSliderProps) => {
  const STEP = 1;
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [sliderLineStyle, setSliderLineStyle] = useState({ leftMargin: '0px', activeWidth: '0px' });

  const handleDragEnd = () => {
    if (isDragging) {
      setIsDragging(false);
      debouncedOnDragEnd(onDragEnd);
    }
  };

  const handleSingleTempChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDragging(true);
    setTemperature?.(Number(event.target.value));
  }, [setTemperature]);

  const handleCoolTemperatureChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDragging(true);
    const newCoolTemp = Number(event.target.value);
    if (newCoolTemp < limits.cool.min || newCoolTemp > limits.cool.max) {
      return;
    }
    setCoolTemperature?.(newCoolTemp);
  }, [setCoolTemperature, heatTemperature]);

  const handleHeatTemperatureChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDragging(true);
    const newHeatTemp = Number(event.target.value);
    if (newHeatTemp < limits.heat.min || newHeatTemp > limits.heat.max) {
      return;
    }
    setHeatTemperature?.(newHeatTemp);
  }, [setHeatTemperature, coolTemperature]);

  useEffect(() => {
    if (sliderRef.current) {
      const sliderWidth = sliderRef.current.offsetWidth;
      const thumbWidth = 30; // Width of the thumb in pixels

      const range = limits.overall.max - limits.overall.min;
      const widthPerStep = (sliderWidth - thumbWidth) / range;

      const lowerTemp = Math.min(coolTemperature, heatTemperature);
      const higherTemp = Math.max(coolTemperature, heatTemperature);

      const lowerTempSteps = lowerTemp - limits.overall.min;
      const higherTempSteps = higherTemp - limits.overall.min;

      // Adjust marginLeft to start at the edge of the left thumb
      const marginLeft = (lowerTempSteps * widthPerStep) + thumbWidth;

      // Adjust the line's width to stop at the edge of the right thumb
      const totalWidth = (higherTempSteps - lowerTempSteps) * widthPerStep;
      const adjustedWidth = totalWidth - thumbWidth; // Subtract the thumb width

      setSliderLineStyle({
        leftMargin: `${marginLeft}px`,
        activeWidth: `${Math.max(0, adjustedWidth)}px`,
      });
    }
  }, [coolTemperature, heatTemperature, limits, sliderRef.current]);

  useEffect(() => {
    // Attach the event listeners
    const sliderContainer = sliderRef.current;
    if (sliderContainer) {
      sliderContainer.addEventListener('mouseup', handleDragEnd);
      sliderContainer.addEventListener('touchend', handleDragEnd);
    }

    // Cleanup function to remove event listeners
    return () => {
      if (sliderContainer) {
        sliderContainer.removeEventListener('mouseup', handleDragEnd);
        sliderContainer.removeEventListener('touchend', handleDragEnd);
      }
    };
  }, [isDragging, onDragEnd]);

  const renderSlider = () => {
    if (type === 'auto') {
      return (
        <StyledDualTemperatureRangeWrapper ref={sliderRef}>
          <StyledTemperatureSlider
            role="slider"
            aria-label="temperature-slider-cool"
            data-testid="temperature-slider-cool"
            type="range"
            id="range-slider-cool"
            temperature="cool"
            value={coolTemperature}
            onChange={handleCoolTemperatureChange}
            min={limits.overall.min}
            max={limits.overall.max}
            step={STEP}
          />
          <StyledTemperatureSlider
            role="slider"
            aria-label="temperature-slider-heat"
            data-testid="temperature-slider-heat"
            type="range"
            id="range-slider-heat"
            temperature="heat"
            value={heatTemperature}
            onChange={handleHeatTemperatureChange}
            min={limits.overall.min}
            max={limits.overall.max}
            step={STEP}
          />
          <StyledDualTemperatureSlider
            leftMargin={sliderLineStyle.leftMargin}
            activeWidth={sliderLineStyle.activeWidth}
          />
        </StyledDualTemperatureRangeWrapper>
      );
    } if (type === 'cool' || type === 'heat') {
      return (
        <StyledTemperatureSlider
          role="slider"
          aria-label="temperature-slider"
          data-testid="temperature-slider"
          type="range"
          id="range-slider"
          temperature={type}
          value={temperature}
          onChange={handleSingleTempChange}
          min={limits[type].min}
          max={limits[type].max}
          step={STEP}
        />
      );
    }

    return null;
  };

  return (
    <StyledTemperatureRangeWrapper>
      <StyledRangedSliderContainer ref={sliderRef}>
        {renderSlider()}
      </StyledRangedSliderContainer>
    </StyledTemperatureRangeWrapper>
  );
};

export default TemperatureSlider;
