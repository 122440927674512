import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import useUUIDStorageManager from './use-uuid-storage-manager.hook';

export const useSyncUserUUID = () => {
  const { fetchUserUUID, updateUserUUID } = useUUIDStorageManager();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const uuidFromStorage = fetchUserUUID();
    if (uuidFromStorage) {
      updateUserUUID(uuidFromStorage);
    }

    const queryParams = new URLSearchParams(location.search);
    const uuid = queryParams.get('uuid');
    if (uuid) {
      updateUserUUID(uuid);
    }
  }, [location, navigate]);
};
