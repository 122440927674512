import { fetchApiData, postApiData, ApiResponse } from './apiService';
import { ApiResponseSingleThermostatData } from '../types/apiResponseSingleThermostatData';
import { ApiResponseThermostatsData } from '../types/apiResponseThermostatsData';

const baseUrl = process.env.REACT_APP_ECOBEE_API_URL;

export const fetchThermostatData = async (uuid: string): Promise<ApiResponse<ApiResponseThermostatsData>> => {
  const url = new URL(`${baseUrl}/thermostats`);

  url.searchParams.append('uuid', uuid);

  return fetchApiData<ApiResponseThermostatsData>(url.toString());
};

export const fetchSingleThermostatData = async (
  uuid: string,
  thermostatId: number | string,
): Promise<ApiResponse<ApiResponseSingleThermostatData>> => {
  const url = new URL(`${baseUrl}/thermostats/${thermostatId}`);
  url.searchParams.append('uuid', uuid);

  return fetchApiData<ApiResponseSingleThermostatData>(url.toString());
};

/*
 * POST on /ecobee-auth/disconnect - disconnect from Ecobee service
 */
export const disconnectEcobee = async (uuid: string) => {
  const url = new URL(`${baseUrl}/ecobee-auth/disconnect`);
  url.searchParams.append('uuid', uuid);

  return postApiData({})(url.toString());
};

export const resumeProgram = async (thermostatId: string, uuid: string) => {
  const url = new URL(`${baseUrl}/thermostats`);
  url.searchParams.append('uuid', uuid);

  const body = JSON.stringify({
    selection: {
      selectionType: 'thermostats',
      selectionMatch: thermostatId,
    },
    functions: [
      {
        type: 'resumeProgram',
        params: {
          resumeAll: false,
        },
      },
    ],
  });

  const options = {
    body,
  };

  return postApiData({
    ...options,
  })(url.toString());
};

export const updateThermostatData = async (uuid: string, thermostatId: number | string, data: object) => {
  const url = new URL(`${baseUrl}/thermostats`);
  url.searchParams.append('uuid', uuid);

  const completePayload = {
    selection: {
      selectionType: 'thermostats',
      selectionMatch: thermostatId.toString(),
    },
    ...data,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(completePayload),
  };

  return postApiData(options)(url.toString());
};
