import { useCallback, useEffect, useState } from 'react';

import { ThermostatSettingsList } from '../types/thermostatSettingsList';
import { Option } from '../types/option';
import { ThermostatPageSettings } from '../types/thermostatPageSettings';
import { updateThermostatData } from '../services/thermostatService';
import { ThermostatData } from '../types/thermostatData';
import { generatePayloadFromOption } from '../utils/termostatUtils';

type ValidCategory = 'mode' | 'fan' | 'status' | 'thermostat';

const updateListSelection = (list: Option[], selectedId: number | string): Option[] => list.map((item) => ({
  ...item,
  selected: item.id === selectedId,
}));

const updateSettings = (
  prevSettings: ThermostatPageSettings,
  category: ValidCategory,
  option: Option,
): ThermostatPageSettings => ({
  ...prevSettings,
  [category]: option,
});

const updateSettingsList = (
  prevList: ThermostatSettingsList,
  category: ValidCategory,
  updatedList: Option[],
): ThermostatSettingsList => ({
  ...prevList,
  [category]: updatedList,
});

export const useThermostatSettings = (
  toggleModalOpened: () => void,
  initialData: {
    settings: ThermostatPageSettings | null,
    settingsList: ThermostatSettingsList | null,
  },
  userUUID: string,
  selectedThermostat: ThermostatData,
  setIsRefreshing: (value: boolean) => void,
  setShowRefreshing: (value: boolean) => void,
  thermostats: ThermostatData[],
  setSelectedThermostat: (value: ThermostatData) => void,
) => {
  const [settings, setSettings] = useState<ThermostatPageSettings>(initialData?.settings || null);
  const [settingsList, setSettingsList] = useState<ThermostatSettingsList>(initialData?.settingsList || null);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const handleOptionSelect = useCallback((option: Option) => {
    if (!option.requireSaveClick && option.category === 'thermostat') {
      const [newSelection] = thermostats.filter((t) => t.identifier === option.id);
      setIsRefreshing(true);
      setSelectedThermostat(newSelection);
      setSettings((prev) => ({ ...prev, thermostat: option }));
      setSettingsList((prev) => ({ ...prev, thermostat: updateListSelection(settingsList.thermostat, option.id) }));
      setTimeout(() => { toggleModalOpened(); }, 1000);
    } else {
      setSelectedOption(option);
    }
  }, [settingsList]);

  const handleSaveClick = useCallback(async () => {
    if (!selectedOption) return;
    setShowRefreshing(true);
    toggleModalOpened();
    const payload = generatePayloadFromOption(selectedOption, selectedThermostat.thermostatTime);

    try {
      const response = await updateThermostatData(userUUID, selectedThermostat.identifier, payload);
      if (!response.error) {
        const category = selectedOption.category as ValidCategory;
        const updatedList = updateListSelection(settingsList[category], selectedOption.id);
        setSettingsList((prev) => updateSettingsList(prev, category, updatedList));
        setSettings((prev) => updateSettings(prev, category, selectedOption));
      }
    } finally {
      setTimeout(() => {
        setIsRefreshing(true);
      }, 1000);
    }
  }, [selectedOption, settingsList, userUUID, selectedThermostat?.identifier, toggleModalOpened]);

  useEffect(() => {
    if (initialData?.settings && initialData?.settingsList) {
      setSettings(initialData.settings);
      setSettingsList(initialData.settingsList);
    }
  }, [initialData]);

  return {
    settings,
    settingsList,
    selectedOption,
    handleOptionSelect,
    handleSaveClick,
  };
};
