import { ActionType, createReducer } from 'typesafe-actions';

import * as themeActions from './actions';
import { ThemeState } from './types';

export const initialState: ThemeState = {
  theme: null,
  loading: false,
  error: null,
};

const getBuildingThemeRequestHandler = (state: ThemeState) => ({
  ...state,
  loading: true,
});

const getBuildingThemeSuccessHandler = (
  state: ThemeState,
  { payload }: ActionType<typeof themeActions.getBuildingTheme.success>,
): ThemeState => ({
  ...state,
  theme: payload,
  loading: false,
  error: null,
});

const getBuildingThemeFailureHandler = (
  state: ThemeState,
  { payload: error }: ActionType<typeof themeActions.getBuildingTheme.failure>,
): ThemeState => ({
  ...state,
  loading: false,
  error: error.error,
});

const themeReducer = createReducer(initialState)
  .handleAction(themeActions.getBuildingTheme.request, getBuildingThemeRequestHandler)
  .handleAction(themeActions.getBuildingTheme.success, getBuildingThemeSuccessHandler)
  .handleAction(themeActions.getBuildingTheme.failure, getBuildingThemeFailureHandler);

export default themeReducer;
