import React from 'react';
import { useIntl } from 'react-intl';

import { StyledButton } from './styles';

interface SettingsButtonProps {
    name: string
    textId?: string
    variant?: 'primary' | 'disconnect'
    icon?: React.ReactNode
    onClick?: () => void;

}

export const SettingsButton = ({
  textId, variant, icon, onClick, name,
}:SettingsButtonProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledButton aria-label={name} onClick={onClick} variant={variant} type="button">
      <span>
        {intl.formatMessage({ id: textId || 'cta.default' })}
      </span>
      {icon && <span>{icon}</span>}
    </StyledButton>
  );
};
