import React from 'react';

import { StyledModal } from 'components/modal/modal.styles';

interface ModalContentProps {
  children: React.ReactNode;
  maxWidth: number | undefined;
  onClose: VoidFunction | undefined;
  variant: 'small' | 'normal' | 'wide';
  withExitButton: boolean;
}

export const ModalContent = ({
  children,
  maxWidth,
  onClose,
  variant,
  withExitButton,
}: ModalContentProps): JSX.Element => (
  <StyledModal
    opened
    maxWidth={maxWidth}
    onClose={onClose}
    dataTestId="modal-window"
    data-cy="modal-window"
    variant={variant}
    withExitButton={withExitButton}
  >
    {children}
  </StyledModal>
);
