import React, { useMemo, useState } from 'react';

import { MiniappSdkDemoInput } from 'pages/miniapp-sdk-demo/miniapp-sdk-demo-input';
import { MiniappSdkMethodDemo } from 'pages/miniapp-sdk-demo/miniapp-sdk-method-demo';

export const MiniappSdkTokenizeCardDemo = (): JSX.Element => {
  const [cardId, setCardId] = useState('');
  const args = useMemo(() => [cardId ? parseInt(cardId, 10) : 0], [cardId]);

  return (
    <MiniappSdkMethodDemo actionGroup="payments" action="tokenizeCard" eventName="tokenizeCardResponse" args={args}>
      <MiniappSdkDemoInput value={cardId} onChange={setCardId} placeholder="Card ID" dataTestId="cardId" />
    </MiniappSdkMethodDemo>
  );
};
