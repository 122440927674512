import React from 'react';
import { useIntl } from 'react-intl';

import { StyledCallToActionButton } from './styles';

interface CallToActionButtonProps {
    textId?: string
    variant?:'primary' | 'modal'
    onClick?: ()=> void ;
    name: string
}

export const CallToActionButton = ({
  textId, variant, onClick, name,
}:CallToActionButtonProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledCallToActionButton
      aria-label={name}
      onClick={onClick}
      variant={variant}
      data-testid="cta-button"
      type="button"
    >
      {intl.formatMessage({ id: textId || 'cta.default' })}
    </StyledCallToActionButton>
  );
};
