import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { UserDialogModal, useBooleanState } from '@hqo/react-components-library';
import { useNavigate } from 'react-router-dom';

import { DisplayWrapper } from 'components/wrapper/styles';
import { OpenInNew } from 'components/icons/open-in-new';
import { disconnectEcobee } from 'services/thermostatService';
import useUUIDStorageManager from 'hooks/use-uuid-storage-manager.hook';
import { SettingsButton } from 'components/button/settings-button/settings-button';

import { ButtonWrapper, Header } from './styles';

export const ThermostatSettings = () => {
  const { value: isModalOpened, toggle: toggleModalOpened } = useBooleanState(false);

  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { userUUID, removeUserUUID } = useUUIDStorageManager();
  const settingsPageExternalUrl = process.env.REACT_APP_ECOBEE_SETTINGS_PAGE;

  const goToExternalUrl = useCallback(() => {
    window.open(settingsPageExternalUrl, '_blank');
  }, []);

  const onDisconnectConfirm = async () => {
    try {
      await disconnectEcobee(userUUID);
      removeUserUUID();
      toggleModalOpened();
      return navigate('/entry', { replace: true });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const onDisconnectCancel = () => {
    toggleModalOpened();
  };

  return (
    <DisplayWrapper>
      <Header data-testid="settings-header">{formatMessage({ id: 'settings.title' })}</Header>
      <ButtonWrapper>
        <SettingsButton
          name="setting-disconnect"
          onClick={toggleModalOpened}
          variant="primary"
          textId="settings.disconnect"
        />
        <SettingsButton
          onClick={goToExternalUrl}
          name="setting-manage"
          variant="primary"
          icon={<OpenInNew />}
          textId="settings.manage"
        />
      </ButtonWrapper>
      <UserDialogModal
        onClose={toggleModalOpened}
        title={formatMessage({ id: 'disconnect.title' })}
        isVisible={isModalOpened}
        description={formatMessage({ id: 'disconnect.text' })}
        buttons={[
          { title: formatMessage({ id: 'disconnect.confirm' }), handler: onDisconnectConfirm },
          {
            title: formatMessage({ id: 'disconnect.cancel' }),
            handler: onDisconnectCancel,
          },
        ]}
      />
    </DisplayWrapper>
  );
};
