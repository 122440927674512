import React, { useCallback, useRef, useState } from 'react';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import { useBooleanState } from '@hqo/react-components-library';

import '../../components/modal/swipe-modal/swipe-modal.css';
import SwipeModalContent from 'components/modal/swipe-modal/swipe-modal-content';
import { CallToActionButton } from 'components/button/call-to-action-button';

import { fanOptions } from '../../constants/fanOptions';
import { thermostatsDataMock } from '../../mocks/thermostatsDataMock';
import { Option } from '../../types/option';
import { Fire } from '../../components/icons/fire';
import { Snowflake } from '../../components/icons/snowflake';

export const TileSelector = (): JSX.Element => {
  const modalRef = useRef(null);
  const { value: isModalOpened, toggle: toggleModalOpened } = useBooleanState(false);
  const [saveOption, setSaveOption] = useState(true);
  const [modalContent, setModalContent] = useState(0);
  const [title, setTitle] = useState('');

  const modesData: Option[] = [
    {
      id: 1,
      name: 'tile-heat',
      textId: 'tile.heat',
      startIcon: <Fire />,
      category: 'mode',
      selected: true,
      requireSaveClick: true,
    },
    {
      id: 2,
      name: 'tile-cool',
      textId: 'tile.cool',
      startIcon: <Snowflake />,
      category: 'mode',
      selected: false,
      requireSaveClick: true,
    },
    {
      id: 3,
      name: 'tile-off',
      textId: 'tile.off',
      category: 'mode',
      selected: false,
      requireSaveClick: true,
    },
  ];

  const getModalOptions = () => {
    switch (modalContent) {
    case 1: return modesData;
    case 2: return fanOptions;
    case 3: return thermostatsDataMock;
    default: return [];
    }
  };

  const openModeModal = useCallback(() => {
    setSaveOption(true);
    setTitle('Mode');
    setModalContent(1);
    toggleModalOpened();
  }, []);

  const openFanModal = useCallback(() => {
    setSaveOption(true);
    setTitle('Set Fan Timer');
    setModalContent(2);
    toggleModalOpened();
  }, []);

  const openThermostatModal = useCallback(() => {
    setSaveOption(false);
    setTitle('Select Thermostat');
    setModalContent(3);
    toggleModalOpened();
  }, []);

  const handleOptionSelect = useCallback((option: Option) => {
    console.log('handleOptionSelect', option);
  }, []);

  return (
    <>
      <button
        disabled={isModalOpened}
        aria-label="modal-open-mode-button"
        onClick={openModeModal}
        type="button"
      >
        Mode
      </button>
      <button
        disabled={isModalOpened}
        aria-label="modal-open-fan-button"
        onClick={openFanModal}
        type="button"
      >
        Fan
      </button>
      <button
        disabled={isModalOpened}
        aria-label="modal-open-thermostats-button"
        onClick={openThermostatModal}
        type="button"
      >
        Thermostats
      </button>

      {isModalOpened && (
        <SwipeModal
          content={<SwipeModalContent options={getModalOptions()} onOptionSelect={handleOptionSelect} />}
          onClose={toggleModalOpened}
          hasBackground={false}
          isContentSlidable
          reverseAnimation={false}
          ref={modalRef}
          className="swipe-modal-content"
          title={title}
          footerContent={saveOption && (
            <CallToActionButton
              name="tile-save"
              onClick={toggleModalOpened}
              variant="modal"
              textId="tile.save"
            />
          )}
        />
      )}
    </>
  );
};
