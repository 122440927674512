import styled from 'styled-components';

interface StyledButton {
    variant?: 'primary' | 'disconnect'
    selected?: boolean;
}

export const StyledButton = styled.button<StyledButton>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Roboto;
    width: 95%;
    height: 54px;
    padding: 5px 16px;
    margin: 8px 0px;
    border:none;
    border-radius: 8px;
    cursor: pointer;
    ${(props) => props.variant === 'disconnect' && 'color: #D52465;'}
    ${(props) => props.variant === 'primary' && 'color: #2D3338;'}
    background-color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    box-shadow: 0px 2px 10px 0px rgba(54,54,54, 0.2);
`;
