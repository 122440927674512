export const fanOptions = [
  {
    id: 1,
    name: '15-min-fan',
    textId: 'tile.duration.minute',
    duration: 15,
    minutes: 15,
    category: 'fan',
    selected: false,
    requireSaveClick: true,
  },
  {
    id: 2,
    name: '30-min-fan',
    textId: 'tile.duration.minute',
    duration: 30,
    minutes: 30,
    category: 'fan',
    selected: false,
    requireSaveClick: true,
  },
  {
    id: 3,
    name: '45-min-fan',
    textId: 'tile.duration.minute',
    duration: 45,
    minutes: 45,
    category: 'fan',
    selected: false,
    requireSaveClick: true,
  },
  {
    id: 4,
    name: '1-hour-fan',
    textId: 'tile.duration.hour',
    duration: 1,
    minutes: 60,
    category: 'fan',
    selected: false,
    requireSaveClick: true,
  },
  {
    id: 5,
    name: '2-hour-fan',
    textId: 'tile.duration.hours',
    duration: 2,
    minutes: 120,
    category: 'fan',
    selected: false,
    requireSaveClick: true,
  },
  {
    id: 6,
    name: 'tile-indefinite',
    textId: 'tile.indefinite',
    category: 'fan',
    selected: false,
    requireSaveClick: true,
  },
];
