import React from 'react';

import { StyledArrowButton } from 'components/icons/styles';

import ArrowDown from './assets/arrow-drop-down.svg';

interface ArrowDropDownProps {
    onClick?: () => void;
}

export const ArrowDropDown = ({ onClick }:ArrowDropDownProps) => (
  <StyledArrowButton
    data-testid="drop-down-thermostat"
    aria-label="drop-down-thermostat"
    type="button"
    onClick={onClick}
  >
    <img src={ArrowDown} alt="SVG Icon" />
  </StyledArrowButton>
);
