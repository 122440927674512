import React from 'react';

import { Fire } from 'components/icons/fire';
import { Snowflake } from 'components/icons/snowflake';

import { Option } from '../types/option';

export const modeOptions: Option[] = [
  {
    id: 1,
    name: 'tile-heat',
    textId: 'tile.heat',
    startIcon: <Fire />,
    category: 'mode',
    selected: true,
    requireSaveClick: true,
  },
  {
    id: 2,
    name: 'tile-cool',
    textId: 'tile.cool',
    startIcon: <Snowflake />,
    category: 'mode',
    selected: false,
    requireSaveClick: true,
  },
  {
    id: 3,
    name: 'tile-auto',
    textId: 'tile.auto',
    category: 'mode',
    selected: false,
    requireSaveClick: true,
  },
  {
    id: 4,
    name: 'tile-off',
    textId: 'tile.off',
    category: 'mode',
    selected: false,
    requireSaveClick: true,
  },

];
