import styled from 'styled-components';

export const Header = styled.h2`
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 0px;
    color: #363636;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
