import styled from 'styled-components';

export const StyledSettingsButtonText = styled.span`
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    color: #2D3338;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align:left;
`;

export const StyledSettingSelectButton = styled.button`
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin: 0px 4px;
    width: 112px;
    padding: 12px 12px 8px 12px;
    background-color: #F1F3F4;
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    &:nth-child(2) {
        ${StyledSettingsButtonText}:nth-child(2) {
            display: flex;
        }
    }
`;

export const StyledSettingsButtonTitle = styled.span`
    font-family: Roboto;
    font-weight: 500;
    font-size: 12px;
    color: #696E72;
    text-transform: uppercase;
`;
