import styled from 'styled-components';
import { ArrowRightChevron, Ellipsis } from '@hqo/react-components-library';

export const TemperatureHeading = styled.h2`
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 0px;
    display: inline;
    color: #2d3338;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;
export const ThermostatSetting = styled(Ellipsis)`
    float: right;
    color: #00263e;
    margin: 3px 3.5% 0 0;
    cursor: pointer;
`;

export const ThermostatWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 317px;
    max-height: 45px;
    padding: 16px;
    border-radius: 4px;
    margin-top: 20px;
    background-color: #fff;
    position: relative;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media (max-width: 300px) {
        padding: 12px 6px;
    }
`;

export const ThermostatNameWrapper = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const DisplayTemperature = styled.div`
    background-color: #fff;
    border: none;
    @media (max-width: 340px) {
        margin-right: 7px;
    }
`;
export const ShowThermostat = styled(ArrowRightChevron)`
    margin-left: 16px;
    background-color: #fff;
    border: none;
    color: #00263e;
`;
export const TemperatureDegrees = styled.span`
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    color: #696e72;
`;
export const FarenheitSign = styled.span`
    font-family: Roboto;
    font-size: 8px;
    font-weight: 300;
    line-height: 9px;
    letter-spacing: 0em;
    text-align: left;
    color: #696e72;
    position: absolute;
    top: 15px;
`;
export const OfflineThermostat = styled.span`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #696e72;
    position: absolute;
    top: 13px;
    left: 77%;
    @media (max-width: 340px) {
        left: 70%;
    }
    @media (max-width: 250px) {
        left: 60%;
    }
`;
