import React, { useMemo, useState } from 'react';

import { MiniappSdkDemoInput } from 'pages/miniapp-sdk-demo/miniapp-sdk-demo-input';
import { MiniappSdkMethodDemo } from 'pages/miniapp-sdk-demo/miniapp-sdk-method-demo';

export const MiniappSdkShowNativePaymentAuth = (): JSX.Element => {
  const [type, setType] = useState('');
  const [currency, setCurrency] = useState('');
  const [total, setTotal] = useState('');
  const [subtotal, setSubtotal] = useState('');
  const [tax, setTax] = useState('');
  const [gatewayId, setGatewayId] = useState('');
  const args = useMemo(
    () => [
      type,
      {
        currency,
        authorization_total: parseFloat(total),
        subtotal: parseFloat(subtotal),
        tax: parseFloat(tax),
        payment_gateway_id: parseInt(gatewayId, 10),
      },
    ],
    [type, currency, total, subtotal, tax, gatewayId],
  );

  return (
    <MiniappSdkMethodDemo
      actionGroup="payments"
      action="showNativePayment"
      eventName="showNativePaymentResponse"
      args={args}
    >
      <MiniappSdkDemoInput value={type} onChange={setType} placeholder="Type" dataTestId="type" />
      <MiniappSdkDemoInput value={currency} onChange={setCurrency} placeholder="Currency" dataTestId="currency" />
      <MiniappSdkDemoInput value={total} onChange={setTotal} placeholder="Authorization Total" dataTestId="total" />
      <MiniappSdkDemoInput value={subtotal} onChange={setSubtotal} placeholder="Subtotal" dataTestId="subtotal" />
      <MiniappSdkDemoInput value={tax} onChange={setTax} placeholder="Tax" dataTestId="tax" />
      <MiniappSdkDemoInput value={gatewayId} onChange={setGatewayId} placeholder="Gateway ID" dataTestId="gatewayId" />
    </MiniappSdkMethodDemo>
  );
};
